import React, { createRef } from 'react';
import customWoodImage from '../assets/img/custom-wood.svg'
import plusImage from '../assets/img/plus.svg'
import customLeatherImage from '../assets/img/custom-leather.svg'
import customPatternImage from '../assets/img/custom-pattern.svg'
import arrowCircleBeigeImage from '../assets/img/arrow-circle-beige.svg'
import heroImage from '../assets/img/hero-placeholder.jpg'
import Footer from './Footer';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import { Helmet } from 'react-helmet-async';

export default class ContentHome extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = createRef();
    }

    render() {
        return (
            <LocomotiveScrollProvider
                options={
                    {
                        smooth: true,
                    }
                }
                watch={[]}
                containerRef={this.containerRef}
            >
                <Helmet>
                    <title>Brainframe | Frame & djembe drums</title>
                    <meta name="title" content="Brainframe | Frame drums and djembe drums"></meta>
                    <meta name="description" content="Artisan drums hand-made in Europe. Shop now, we ship worldwide!"></meta>
                </Helmet>
                <div data-scroll-container ref={this.containerRef}>
                    <div data-scroll-section>
                    <section className="hero is-black is-fullheight">
                            <div className="hero-video">
                                <img src={heroImage} alt="Image of a frame drum and a djembe drum" />
                                <div className="hero-video-overlay"></div>
                            </div>
                            <div className="hero-body">
                                <div className="container has-text-centered">
                                    <p className="title-main is-size-4 mb-medium mt-medium" data-scroll data-scroll-speed="1">Artisan drums handmade in Europe</p>
                                    <div className="mb-large" data-scroll data-scroll-speed="2">
                                        <a className="button is-multiple is-rounded is-primary has-text-black is-size-6" href="/frame-drums">Shop frame drums</a>
                                        <a className="button is-multiple is-rounded is-primary has-text-black is-size-6" href="/djembe-drums">Shop djembe drums</a>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id="home-pitch" className="section">
                            <div className="container">
                                <div className="columns is-centered is-multiline">
                                    <div className="column is-7 has-text-centered">
                                        <p className="is-size-4 has-text-weight-light mt-large mb-medium" data-scroll data-scroll-speed="1">While recreating ancient instruments with the addition of modern details, we have evoked pristine vibrations in the upgraded form of the oldest known drum – <u>frame drum</u>.  In addition, we've introduced <u>the djembe</u>, combining tradition with innovation to bring you another exceptional instrument that embodies the rich heritage of percussion.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container">
                                <div className="columns is-centered">
                                    <div className="column is-6 has-text-centered">
                                        <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">The process</h3>
                                        <p className="is-size-5 is-relative" data-scroll data-scroll-speed="2">Knowing that the <u>smallest details</u> make the difference, as well as combining <u>natural materials</u> and careful <u>craftsmanship</u>, we stepped out of the frame to create an outstanding instrument.</p>
                                        <div className="text-over-image home-process">
                                            <div className="text-over-image-content">
                                                <a href="/process">
                                                    <img src={arrowCircleBeigeImage} width="70" height="70" alt="Read more" />
                                                    <div>Read more</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container">
                                <div className="columns is-centered">
                                    <div className="column is-6 has-text-centered">
                                        <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="-1">The maker</h3>
                                        <p className="is-size-5 is-relative" data-scroll data-scroll-speed="2">“As long as I can remember, my aspiration and communication with the world and people happened through music, with <u>drums</u> and <u>rhythms</u> being flawless in making it possible.”</p>
                                        <div className="text-over-image home-maker">
                                            <div className="text-over-image-content">
                                                <a href="/maker">
                                                    <img src={arrowCircleBeigeImage} width="70" height="70" alt="Read more" />
                                                    <div>Read more</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container">
                                <div className="columns is-centered">
                                    <div className="column is-6 has-text-centered">
                                        <h3 className="title is-size-4 mt-xlarge mb-large" data-scroll data-scroll-speed="0">Create your frame drum</h3>
                                        <div className="home-customize" data-scroll data-scroll-speed="1">
                                            <img src={customWoodImage} width="103" height="163" alt="" />
                                            <img src={plusImage} width="40" height="42" className="mx-large my-large" style={{ opacity: 0.3 }} alt="" />
                                            <img src={customLeatherImage} width="139" height="164" alt="" />
                                            <img src={plusImage} width="40" height="42" className="mx-large my-large" style={{ opacity: 0.3 }} alt="" />
                                            <img src={customPatternImage} width="131" height="131" alt="" />
                                        </div>
                                        <p className="is-size-5 is-relative mt-large mb-large" data-scroll data-scroll-speed="2">Each frame drum we make is unique. We offer a wide range of wood types and membrane selections. Furthermore, we can engrave custom designs on your frame drum.</p>
                                        <a href="/custom" className="button is-rounded is-primary has-text-black is-size-6 mb-large plausible-event-name=CTA+Customize+drum" data-scroll data-scroll-speed="4">Create your frame drum</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </LocomotiveScrollProvider>
        );
    }
}